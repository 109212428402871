<template>
  <form @keyup.enter="salvarDados">
    <b-field grouped class="column-direction-touch">
      <b-field
        expanded
        label="Nome"
        :type="campoInvalido('name', 'data') ? 'is-danger' : ''"
        :message="campoInvalido('name', 'data') ? 'Nome é um campo obrigatório' : ''"
      >
        <b-input placeholder="Nome" v-model="$v.data.name.$model"></b-input>
      </b-field>

      <b-field
        expanded
        label="E-mail"
        :type="campoInvalido('email', 'data') ? 'is-danger' : ''"
        :message="campoInvalido('email', 'data') ? 'Insira um e-mail válido' : ''"
      >
        <b-input
          placeholder="E-mail"
          v-model="$v.data.email.$model"
          @input="data.email = data.email.toLowerCase()"
        ></b-input>
      </b-field>
    </b-field>

    <b-field grouped class="column-direction-touch">
      <b-field
        expanded
        label="Telefone"
        :type="campoInvalido('telephone', 'data') ? 'is-danger' : ''"
        :message="campoInvalido('telephone', 'data') ? 'Telefone é um campo obrigatório' : ''"
      >
        <b-input
          expanded
          placeholder="Telefone"
          maxlength="12"
          :has-counter="false"
          v-mascara:telefone
          v-model="$v.data.telephone.$model"
          onkeypress="return event.charCode >= 48 && event.charCode <= 57"
        />
      </b-field>

      <b-field
        expanded
        label="Celular"
        :type="campoInvalido('cellphone', 'data') ? 'is-danger' : ''"
        :message="campoInvalido('cellphone', 'data') ? 'Celular é um campo obrigatório' : ''"
      >
        <b-input
          expanded
          placeholder="Celular"
          maxlength="13"
          :has-counter="false"
          v-mascara:telefone
          v-model="$v.data.cellphone.$model"
          onkeypress="return event.charCode >= 48 && event.charCode <= 57"
        />
      </b-field>

      <b-field
        expanded
        label="Sexo"
        :type="campoInvalido('gender', 'data') ? 'is-danger' : ''"
        :message="campoInvalido('gender', 'data') ? 'Sexo é um campo obrigatório' : ''"
      >
        <b-select
          expanded
          placeholder="Sexo"
          v-model="$v.data.gender.$model"
          @click.native="$v.data.gender.$touch()"
        >
          <option v-for="gender in genderOptions" :key="gender.id" :value="gender.id">
            {{ gender.name }}
          </option>
        </b-select>
      </b-field>
    </b-field>

    <b-button
      class="button is-primary is-fullwidth save-button"
      :loading="loading"
      @click.prevent.stop="salvarDados"
    >
      Salvar
    </b-button>
  </form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { email, required } from 'vuelidate/lib/validators';

import campo from '@/mixins/formulario';

export default {
  name: 'ProfileData',
  mixins: [campo],
  data() {
    const data = {
      cellphone: '',
      email: '',
      name: '',
      gender: '',
      telephone: '',
    };
    const genderOptions = [
      { id: 'MASCULINO', name: 'Masculino' },
      { id: 'FEMININO', name: 'Feminino' },
    ];

    return {
      data,
      genderOptions,
      loading: false,
    };
  },
  computed: {
    ...mapGetters(['getConfirmedUserEmail', 'getUser', 'getLogged']),
  },
  async beforeRouteLeave(to, from, next) {
    if (this.getLogged && this.$v.data.$anyDirty) {
      const sair = await this.$confirmacao({
        message: 'Os dados serão perdidos. Tem certeza que deseja continuar?',
      });
      return next(sair);
    }
    return next();
  },
  validations: {
    data: {
      name: { required },
      email: { email, required },
      telephone: { required },
      cellphone: { required: false },
      gender: { required },
    },
  },
  methods: {
    ...mapActions(['requestEmailCode', 'updateUser']),
    loadUser() {
      this.data.name = this.getUser.nome;
      this.data.email = this.getUser.email;
      this.data.telephone = this.getUser.telefone;
      this.data.cellphone = this.getUser.celular;
      this.data.gender = this.getUser.sexo;
    },
    async salvarDados() {
      if (!this.validar('data')) {
        return;
      }

      this.loading = true;
      try {
        const data = {
          celular: this.data.cellphone,
          email: this.data.email,
          nome: this.data.name,
          sexo: this.data.gender,
          telefone: this.data.telephone,
        };
        await this.updateUser(data);
        this.$v.data.$reset();
        this.$alerta('Dados salvos', 'is-success');

        if (!this.getConfirmedUserEmail) {
          this.requestEmailCode();
          this.$router.push({ path: '/confirmar-email' });
        }
      } catch (error) {
        const { response } = error;
        if (response.data.error.type === 'EmailExists') {
          this.$alerta('E-mail já cadastrado', 'is-danger');
          return;
        }

        this.$alerta(error.message, 'is-danger');
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.loadUser();
  },
};
</script>
